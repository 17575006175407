import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { CssGrid } from "../components/CssGrid"
import { Hero } from "../components/Hero"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { Button } from "../components/Button"
import { Content } from "../components/Content"
import { Subnavigation } from "../components/Subnavigation"
import "./MediaCentre.scss"

let SUBMENU = []

const Downloads = ({ location }) => (
  <StaticQuery
    query={graphql`
      query BlogAllDownloadListQuery {
        allWordpressWpDownload {
          edges {
            node {
              title
              slug
              featured_media {
                source_url
                localFile {
                  childImageSharp {
                    id
                    sizes(maxWidth: 1000) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
              acf {
                file {
                  url {
                    source_url
                  }
                }
              }
            }
          }
        }
        submenu: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "media" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <CssGrid className="internal-page media-centre">
          <div>
            <Hero>
              <Content
                fillBackgroundWithImage
                fillBlockWithImage
                backgroundImage="about_ovolo_banner.jpg"
              >
                <h1>Downloads</h1>
              </Content>
            </Hero>
            {data.submenu.edges.map(({ node }, i) => {
              SUBMENU = node.items
            })}
            <Subnavigation items={SUBMENU} />
            <Breadcrumbs
              categoryName="Downloads"
              categorySlug={location.pathname}
              pageName="Downloads"
            />
            <div className="container">
              <div className="row">
                <CssGrid>
                  <div className="col col-md-12 download">
                    <h3>Choose your download</h3>
                    {data.allWordpressWpDownload.edges.map(({ node }) => (
                      <div key={node.slug} className="item">
                        <div className="entry-media">
                          {node.featured_media && (
                            <a
                              href={`${node.acf.file.url.source_url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <Img
                                src={
                                  node.featured_media.localFile.childImageSharp
                                    .sizes.src
                                }
                                sizes={
                                  node.featured_media.localFile.childImageSharp
                                    .sizes
                                }
                                alt="blog_image_one"
                                className="img-fluid"
                              />
                            </a>
                          )}
                        </div>
                        <div className="entry-body">
                          <h3>
                            <a
                              href={`${node.acf.file.url.source_url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              {node.title}
                            </a>
                          </h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </CssGrid>
              </div>
            </div>
          </div>
        </CssGrid>
      </Layout>
    )}
  />
)

export default Downloads
